import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'

const PageStyles = styled.div`
  text-align: center;

  h1 {
    font-weight: bold;
  }

  hr {
    width: 30%;
    margin: 20px auto;
  }
`

export class FourOhFourComponent extends Component {
  constructor() {
    super()
    this.state = {
      ready: false,
    }
  }

  componentDidMount() {
    this.setState({
      ready: true,
    })
  }

  componentDidUpdate() {
    this.render()
  }

  render() {
    const { ready } = this.state
    if (!ready) {
      return null
    }

    const { location } = this.props

    const path = location.pathname

    return (
      <PageStyles className="content-width">
        <h1>Page Not Found</h1>
        <p>This page does not exist on the current version of this website.</p>
        <p>You may be using an old link that references the legacy site:</p>
        <a className="dark" href={`http://legacy.pioneerdistrict.org${path}`}>
          Click here to view this page on the old Pioneer website
        </a>
        <hr />
        <div>
          <p>
            If you believe this page should exist on this version of the site,
            or you got here by accident,
            <br />
          </p>
          <Link className="dark" to="/about/contact?form=WebsiteIssue">
            click here to submit a website issue report
          </Link>
        </div>
      </PageStyles>
    )
  }
}

export default function FourOhFourPage({ location }) {
  return <FourOhFourComponent location={location} />
}
